import { Box } from '@/components/box';
import { Text } from '@/components/text';
import {
  focusStateStyles,
  thinnerUnderlineHoverStyles,
  Underline,
} from '@/components/underline';
import Image from 'next/image';
import { ButtonText } from '@/components/button-text';
import { styled } from '@/stitches.config';

interface ContactBadgeProps {
  imageSrc: string;
  name: string;
  phoneNumber: string;
  email: string;
  size: 'lg' | 'sm';
}

export function ContactBadge({
  imageSrc,
  name,
  phoneNumber,
  email,
  size,
}: ContactBadgeProps) {
  return (
    <Box
      css={{
        display: 'flex',
        gap: '$4',
        alignItems: 'center',
      }}
    >
      <Box
        css={{
          width: size === 'lg' ? 64 : 50,
          height: size === 'lg' ? 64 : 50,
          flexShrink: 0,
          overflow: 'hidden',
        }}
      >
        <Image
          src={imageSrc}
          alt={`Photo of ${name}`}
          layout="responsive"
          width={480}
          height={480}
        />
      </Box>
      <Text
        as="address"
        size={2}
        css={{
          fontStyle: 'normal',
          display: 'flex',
          flexDirection: 'column',
          gap: '0.35rem',
          paddingTop: '$1',
          margin: 0,
        }}
      >
        <ButtonText href={`mailto:${email}`} label={email} />
        <Anchor href={`tel:${phoneNumber.replace(/\s+/g, '')}`}>
          {phoneNumber}
          <Underline />
        </Anchor>
      </Text>
    </Box>
  );
}

const Anchor = styled('a', {
  position: 'relative',
  display: 'inline-flex',
  fontStyle: 'normal',
  color: 'inherit',
  textDecoration: 'none',
  overflow: 'hidden',

  '&:hover, &:active': {
    [`& ${Underline} > span`]: thinnerUnderlineHoverStyles,
  },

  '&:focus-visible': {
    ...focusStateStyles,
    outlineOffset: '2px',
    [`& ${Underline} > span`]: thinnerUnderlineHoverStyles,
  },
});
