import { Text } from '@/components/text';
import { CSS, styled } from '@/stitches.config';
import { StaticImageData } from 'next/image';
import { Description } from './styled-text';
import photoOfThomas from './thomas-clever.jpg';
import photoOfJulia from './julia-hendriks.jpg';
import photoOfNicolas from './nicolas-zaragoza.jpg';
import { ContactBadge } from '../contact-badge';

const CONTACT_DETAILS: Record<
  'business' | 'careers' | 'esg',
  {
    name: string;
    description: string;
    phoneNumber: string;
    photo: StaticImageData;
    email: string;
  }
> = {
  business: {
    name: 'Thomas Clever',
    description:
      'If you’d like to learn more about how we can add value to your business, contact Thomas Clever.',
    phoneNumber: '+31 6 19 55 29 81',
    photo: photoOfThomas,
    email: 'thomas@cleverfranke.com',
  },
  careers: {
    name: 'Julia Hendriks',
    description:
      'If you’d like to learn more about a job opening or internship, contact Julia Hendriks.',
    phoneNumber: '+31 30 785 19 26',
    photo: photoOfJulia,
    email: 'julia@cleverfranke.com',
  },
  esg: {
    name: 'Nicolás Zaragoza',
    description:
      'To discuss how we can support your ESG initiatives, contact Nicolás Zaragoza.',
    phoneNumber: '+31 6 13 27 04 45',
    photo: photoOfNicolas,
    email: 'nicolaszaragoza@cleverfranke.com',
  },
};

export function InquiryBlock(props: {
  css?: CSS;
  contactPersonType: 'business' | 'careers' | 'esg';
}) {
  return (
    <Wrapper css={props?.css}>
      <Text css={{ display: 'block', fontSize: '$3', marginBlockEnd: '$3' }}>
        Get in touch
      </Text>

      <Description css={{ marginBlockEnd: '1.74rem' }}>
        {CONTACT_DETAILS[props.contactPersonType].description}
      </Description>

      <ContactBadge
        imageSrc={CONTACT_DETAILS[props.contactPersonType].photo.src}
        name={CONTACT_DETAILS[props.contactPersonType].name}
        phoneNumber={CONTACT_DETAILS[props.contactPersonType].phoneNumber}
        email={CONTACT_DETAILS[props.contactPersonType].email}
        size="sm"
      />
    </Wrapper>
  );
}

const Wrapper = styled('div', {});
