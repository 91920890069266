import React from 'react';
import { styled, CSS } from '@/stitches.config';
import { Text } from '@/components/text';
import {
  focusStateStyles,
  Underline,
  underlineHoverStyles,
} from '@/components/underline';
import {
  ExternalLinkXsmall as IconExternal,
  ArrowRightXsmall as IconArrowRight,
} from '@/components/icons';
import NextLink from 'next/link';

const BUTTON_SIZE = 20;

type ButtonProps = {
  href: string;
  label: string;
  css?: CSS;
  textCss?: CSS;
  onClick?: (e: React.MouseEvent) => void;
  isInternalLink?: boolean;
  type?: 'basic' | 'arrow';
};

export function ButtonText({
  href,
  label,
  onClick,
  css,
  textCss,
  isInternalLink = true,
  type = 'basic',
}: ButtonProps) {
  return (
    <NextLink href={href} passHref>
      <ButtonLink
        css={css}
        target={isInternalLink ? '_self' : '_blank'}
        onClick={onClick}
      >
        {type === 'arrow' && (
          <ButtonIconWrapper>
            <ButtonIcon isInternalLink={isInternalLink} />
            <IconUnderLine />
          </ButtonIconWrapper>
        )}

        <ButtonLinkLabel>
          <Text
            size={2}
            css={{
              display: 'block',
              padding: type === 'arrow' ? '$2' : '0 0 $1 0',
              margin: 0,
              lineHeight: '$5',
              ...textCss,
            }}
          >
            {label}
          </Text>
          <Underline />
        </ButtonLinkLabel>
      </ButtonLink>
    </NextLink>
  );
}

type ButtonIconProps = {
  isInternalLink: boolean;
};

function ButtonIcon({ isInternalLink }: ButtonIconProps) {
  if (isInternalLink) {
    return (
      <IconArrowRight
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          transform: 'translateY(-50%)',
          width: '100%',
          height: '100%',
          stroke: 'currentcolor',
          strokeWidth: 0,
        }}
      />
    );
  } else {
    return (
      <IconExternal
        width={BUTTON_SIZE}
        height={BUTTON_SIZE}
        style={{
          position: 'absolute',
          top: '50%',
          left: '0',
          transform: 'translateY(-50%)',
          width: '100%',
          height: '100%',
          stroke: 'currentcolor',
          strokeWidth: 0,
        }}
      />
    );
  }
}

const IconUnderLine = styled('div', {
  position: 'absolute',
  bottom: '-4px',
  width: '100%',
  height: '4px',
  backgroundColor: 'currentColor',
  transition: 'transform',
});

const iconUnderlineHover = {
  ...underlineHoverStyles,
  animationDuration: '200ms',
};

const textUnderlineHover = {
  ...underlineHoverStyles,
  animationDuration: '350ms',
};

const ButtonLink = styled('a', {
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-flex',
  textDecoration: 'none',
  color: 'inherit',

  '&:hover, &:active': {
    [`${Underline} > span `]: textUnderlineHover,
    [`${IconUnderLine}`]: iconUnderlineHover,
  },

  '&:focus-visible': {
    ...focusStateStyles,

    [`${Underline} > span `]: textUnderlineHover,
    [`${IconUnderLine}`]: iconUnderlineHover,
  },

  '@noHover': {
    scrollSnapAlign: 'start',
  },
});

const ButtonIconWrapper = styled('div', {
  position: 'relative',
  width: BUTTON_SIZE + 'px',
});

const ButtonLinkLabel = styled('div', {
  position: 'relative',
});
